<template>
  <div
    v-if="ready"
    class="sticky-top bg-white d-flex flex-column align-items-center text-decoration-none"
  >
    <div class="logo-section mt-5 pb-5">
      <router-link :to="{ name: 'dashboard' }">
        <img src="@/assets/logo.svg" alt="logo" />
      </router-link>
    </div>

    <button
      class="btn rounded-pill bg-primary px-4 my-3 d-flex align-items-center"
    >
      <router-link class="text-white" :to="{ name: 'state-selection' }">
        <span class="pe-1 text-white">+</span>
        New Referral
      </router-link>
    </button>

    <br />

    <ul id="MainMenu" class="nav nav-pills flex-column align-items-start px-3">
      <li class="nav-item py-3">
        <router-link
          :to="{ name: 'dashboard' }"
          class="menu-link align-start px-0"
          :class="user.is_lawyer ? 'tpd-lawyer-hover' : ''"
        >
          <img
            src="@/assets/svg/dashboard.svg"
            alt="icon"
            class="me-2 icon-gray"
          />
          Dashboard
        </router-link>
      </li>

      <li class="nav-item py-3">
        <router-link
          :to="{ name: 'calculator' }"
          class="menu-link align-start px-0"
          :class="user.is_lawyer ? 'tpd-lawyer-hover' : ''"
        >
          <img
            src="@/assets/svg/calculator.svg"
            alt="icon"
            class="me-2 icon-gray"
          />
          Calculator
        </router-link>
      </li>

      <li :class="user.has_wpi_access ? '' : 'd-none'" class="nav-item py-3">
        <router-link
          :to="{ name: 'assessment-list' }"
          class="menu-link align-start px-0"
          :class="user.is_lawyer ? 'tpd-lawyer-hover' : ''"
        >
          <img
            src="@/assets/svg/assessments.svg"
            alt="icon"
            class="me-2 icon-gray"
          />
          RTWSA List
        </router-link>
      </li>

      <li class="nav-item py-3">
        <router-link
          :to="{ name: 'tpd-list' }"
          class="menu-link align-start px-0"
          :class="user.is_lawyer ? 'tpd-lawyer-hover' : ''"
        >
          <img src="@/assets/svg/tpd.svg" alt="icon" class="me-1 icon-gray" />
          TPD List
        </router-link>
      </li>

      <li class="nav-item py-3">
        <router-link
          :to="{ name: 'profile' }"
          class="menu-link align-start px-0"
          :class="user.is_lawyer ? 'tpd-lawyer-hover' : ''"
        >
          <img
            src="@/assets/svg/profile.svg"
            alt="icon"
            class="me-2 icon-gray"
          />
          Profile
        </router-link>
      </li>

      <li class="nav-item py-5" @click="logout">
        <router-link
          :to="{ name: 'login' }"
          class="menu-link align-start px-0"
          :class="user.is_lawyer ? 'tpd-lawyer-hover' : ''"
        >
          <img
            src="@/assets/svg/login.svg"
            alt="icon"
            class="mb-1 me-2 icon-gray"
          />
          Logout
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  components: {},
  data() {
    return {
      user: null,
      ready: false,
    }
  },

  mounted() {
    if (this.$store.state.profile) {
      this.user = this.$store.state.profile
      this.ready = true
    }
  },

  methods: {
    logout() {
      this.$store
        .dispatch('logout', this.login)
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch((error) => {
          console.error(error)
        })
    },

    buildNewReferral() {
      //make sure to reset stored vuex data
      this.$store.state.TPDReferral = null
      this.$router.push({ name: 'tpd-lawyer', params: { id: 'new' } })
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-item {
  margin: 10px 0;
}
.menu-link {
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
  color: #969696;

  .icon-gray {
    filter: brightness(0) saturate(100%) invert(99%) sepia(13%) saturate(0%)
      hue-rotate(142deg) brightness(82%) contrast(86%);
  }
}

.menu-link:hover,
.router-link-active:hover,
.router-link-exact-active {
  color: $primary;

  .icon-gray {
    filter: brightness(0) saturate(100%) invert(70%) sepia(54%) saturate(592%)
      hue-rotate(138deg) brightness(88%) contrast(90%);
  }
}

.sticky-top {
  z-index: 0 !important;
}
</style>
