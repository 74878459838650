<template>
  <div v-if="pageReady && user" class="d-flex main-layout">
    <div class="col-2 px-sm-2 bg-white">
      <Navbar />
    </div>
    <div class="col-10 bg-light z-index-0">
      <div class="container-fluid m-0 overflow-auto py-4">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn } from 'axios-jwt'

import Navbar from '@/components/Navbar.vue'

export default {
  name: 'MainLayout',
  components: { Navbar },

  data() {
    return {
      pageReady: false,
      user: null,
    }
  },

  async mounted() {
    if (!isLoggedIn()) {
      // assume we are logged in because we have a refresh token }
      this.$router.push({ name: 'login' })
    }

    await this.getProfile()

    this.pageReady = true
  },

  methods: {
    async getProfile() {
      await this.$store
        .dispatch('getTPDProfile')
        .then((response) => {
          let data = response.data
          this.$store.state.profile = data
          this.user = data

          //this api get call will automatically return the detail of the logged in user
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.main-layout {
  min-height: 100%;
}
</style>
